<template>
  <div class="box">
    <img class="img" src="../../assets/shouye2_banner333.png" alt="" />
    <div class="mask"></div>
    <div class="content">
      <img src="../../assets/denglu_imgs@3x.png" class="content_img" alt="" />
      <div class="rigth">
        <img class="logo" src="../../assets/logo.png" alt="" />
        <div class="tab">
          <div v-if="$route.query.type == 1" class="item active">
            注册成功请设置密码
          </div>
          <div v-if="$route.query.type == 2" class="item active">设置密码</div>
        </div>
        <div class="phone">
          <img src="../../assets/shouji_icon.png" alt="" />
          <input
            v-model="form.password"
            type="password"
            placeholder="请输入新密码"
          />
        </div>
        <div class="password">
          <img src="../../assets/mima_icon.png" alt="" />
          <input
            v-model="pwsd"
            type="password"
            placeholder="请再次输入新密码"
          />
        </div>
        <div class="btn" @click="confirm">确 认</div>
        <div class="tac">为了您的安全 请密码设置为8-16位数以内</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  data() {
    return {
      form: {
        phone: "",
        password: "",
        Verify: "",
      },
      pwsd: "",
    };
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.userInfo,
    }),
  },
  methods: {
    ...mapActions([
      "getRegiser",
      "getChangePassword",
      "getregister_x",
      "getsetUp_x",
    ]),
    // 修改密码
    confirm() {
      if (this.form.password != this.pwsd) {
        this.$message.error("二次输入密码不一致");
        return;
      }
      //注册
      if (this.$route.query.type == 1) {
        this.getregister_x(this.form).then((res) => {
          this.$message({
            message: res.message,
            type: res.successCode == 200 ? "success" : "cancel",
          });
          if (res.successCode == 200) {
            this.$router.push("/login");
          }
        });
      } else {
        //忘记密码
        this.getsetUp_x({
          id: this.userInfo.id, //学员id
          phone: this.userInfo.phone, //手机号
          Verify: this.form.Verify, //验证码
          password: this.form.password, //密码
        }).then((res) => {
          this.$message({
            message: res.message,
            type: res.successCode == 200 ? "success" : "cancel",
          });
          if (res.successCode == 200) {
            this.$router.push("/login");
          }
        });
      }
      //登录
    },
    // 前往登录
    gologin() {
      this.$router.push("/login");
    },
  },
  mounted() {
    this.form.phone = this.$route.query.phone;
    this.form.Verify = this.$route.query.verify;
    console.log(this.form);
  },
};
</script>

<style lang="scss" scoped>
input:focus {
  outline: none;
  border: 0 none;
}

.box {
  overflow-x: hidden;
  overflow-y: hidden;
  height: 100vh;
  position: relative;
  .img {
    width: 1922px;
    height: 531px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 100;
  }
  .mask {
    width: 1922px;
    height: 531px;
    background: rgba($color: #000000, $alpha: 0.3);
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 1001;
  }
  .content {
    display: flex;
    width: 786px;
    height: 448px;
    background: #ffffff;
    box-shadow: 0px 5px 20px 0px #06316a;
    border-radius: 10px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 1002;
    .content_img {
      width: 318px;
      height: 449px;
      border-radius: 10px 0px 0px 10px;
      vertical-align: middle;
    }
    .rigth {
      flex: 1;
      padding: 36px 64px;
      box-sizing: border-box;
      .logo {
        width: 143px;
        height: 48px;
      }
      .tab {
        margin-top: 42px;
        display: flex;
        justify-content: center;

        .item {
          font-size: 18px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #444444;
        }
        .active {
          font-size: 18px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #1276fb;
        }
      }
      .phone {
        margin-top: 41px;
        padding-bottom: 5px;
        border-bottom: 1px solid #d4d9e2;
        input {
          &::placeholder {
            font-size: 16px;
          }
          margin-left: 13px;
          transform: translateY(-3px);
          font-size: 20px;
          border: 0 none;
          height: 30px;
        }
      }
      .password {
        margin-top: 30px;
        padding-bottom: 5px;
        border-bottom: 1px solid #d4d9e2;
        input {
          &::placeholder {
            font-size: 16px;
          }
          margin-left: 13px;
          transform: translateY(-3px);
          font-size: 20px;
          border: 0 none;
          height: 30px;
        }
        .verification {
          cursor: pointer;
          transform: translateY(-3px);
          float: right;
          text-align: center;
          line-height: 30px;
          width: 105px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
          height: 30px;
          background: #1276fb;
          border-radius: 15px;
        }
      }
      .btn {
        cursor: pointer;
        margin-top: 37px;
        text-align: center;
        line-height: 47px;
        height: 47px;
        background: #1276fb;
        border-radius: 24px;
        font-size: 18px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
      }
      .tac {
        margin-top: 8px;
        text-align: center;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #9c9c9c;
      }
    }
  }
}
</style>
